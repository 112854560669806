.hero{
    height: calc(100vh - 109px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../Img/glamorous.svg');
    background-repeat: repeat;
    background-size: 70px;
    font-family: Fondamento;
    border-image: linear-gradient(#D4BF79, #B0912A) 90;
    border-width: 0px 0px 4px 0px;
    border-style: solid;
    margin-top: 109px;
}
.hero-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}
.hero-element{
    width: 50%;
    display: flex;
}
.hero-content{
    text-align: center;
}
.title{
    width: 70%;
    margin: auto;
}
.subtitle{
    width: 50%;
    margin: auto;
}
.person {
    width: 60%;
    margin: auto;
    background: linear-gradient(to bottom, #D4BF79, #B0912A) border-box;
    border-radius: 50em;
    border: 6px solid transparent;
}

@media screen and (max-width: 1000px){
    .hero-wrapper{
        flex-direction: column-reverse;
    }
    .hero-element{
        margin: 10px;
        width: 70%;
    }
    .person{
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 500px){
    .hero-element{
        width: 100%;
        margin: 0px;
    }
}