.contact{
 padding: 30px 0px;
 display: flex;
 flex-direction: column;
 background-image: url('../Img/pattern3.svg');
 background-position: center;
 background-size: cover;
 background-repeat: no-repeat;
}
.desc{
    display: flex;
    align-items: center;
    justify-content: center;
}
.telephone{
    padding: 0px 0px 0px 30px;
    width: 40%;
}
.telephone-card{
    background-color: #3b0f3e;
    border-radius: 20px;
    padding: 20px;
    line-height: 1.5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.telephone{
    padding: 0px;
}