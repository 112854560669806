@import url('https://fonts.googleapis.com/css2?family=Fondamento:ital@0;1&display=swap');

body {
  margin: 0;
  max-width:100vw;
  background-color: #2D092F;
  color: #FFFFFF;
  font-family: Fondamento;
}
.header{
  margin: auto;
  max-width: 50%;
}
.desc{
  max-width: 900px;
  padding: 30px 20px;
  margin: auto;
  font-size: 30px;
}
a{
  all: unset;
}
button{
  all: unset;
}

@media screen and (max-width: 840px){
  .desc{
    flex-direction: column;
  }
  .contact .desc{
    flex-direction: column-reverse;
  }
}